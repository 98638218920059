.App {
  text-align: center;
}

:root {
  --verde-claro: #B5EAE6;
  --verde-medio: #62C8CA;
  --verde-oscuro: #1F88A2;
  --azul: #072B4A;
  --verde-claro-rgba: rgba(181, 234, 230);
  --verde-medio-rgba: rgba(98, 200, 202);
  --verde-oscuro-rgba: rgba(31, 136, 162);
  --azul-rgba: rgba(7, 43, 74);


  --negro: #000000;
  --blanco: #FFFFFF;
  --limon:#97FFE4;
  --rojo: #FF5C5C;
  --celeste: #0372FF;
  --rojo-rgba: rgba(255, 92, 92);
  --rojo-oscuro: #c9302c;
  --celeste-rgba: rgba(3, 114, 255);


  --gris: #ececec;
  --gris-oscuro: #c0c0c0;
  --celeste-rgba-65: rgba(3, 114, 255, 0.65);

  --verde-oscuro-rgba-65: rgba(31, 136, 162, 0.65);
  --negro-rgba-03: rgba(0,0,0,0.3);
  --negro-rgba-01: rgba(0,0,0,0.1);
  --verde-indicador: rgba(11, 196, 11, 0.7);
}

body {
  font-family: "Poppins", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.submenu-enter {
  opacity: 0;
  transform: scale(0.9);
}

.submenu-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.submenu-exit {
  opacity: 1;
  transform: scale(1);
}

.submenu-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

.blue-button {
  background-color: #072B4A;
  color: white;
}

.custom-toast {
  min-height: 40px;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: #333; /* Fondo oscuro */
  color: #fff; /* Texto blanco */
}

.custom-toast-body {
  display: flex;
  align-items: center;
}

.custom-toast-body > div {
  flex: 1;
}

.custom-toast .Toastify__toast-icon {
  margin-right: 8px;
  color: #4caf50; /* Color del ícono */
}